import { API } from "aws-amplify";
export const GetAllCustomerCategories = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllCustomerCategoriesList: [],
    };
  },
  methods: {
    async GetAllCustomerCategoriesMethod() {
      const path = "list_customer_categories";
      const myInit = {
        body: {
          command: "list_customer_categories",
          organization_id:
            this.get_Current_Organization_DetailsObject.organization_id,
          setting_status : "ACTIVE",
          setting_type : "CUSTOMER_CATEGORY",
        },
      };
      API.post("SERVICEWRK", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.GetAllCustomerCategoriesList = response.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
