import { API } from "aws-amplify";
export const GetAllSupportType = {
  data() {
    return {
      GetAllSupportTypeList: [],
      overlay: false,
      noDataText: "",
    };
  },
  methods: {
    async GetAllSupportTypeMethod(support_type) {
      const path = "list_customer_categories";
      const myInit = {
        body: {
          command: "list_customer_categories",
          organization_id:
            this.get_Current_Organization_DetailsObject.organization_id,
          setting_status: "ACTIVE",
          setting_type: "AMC",
          support_type : support_type
        },
      };
      API.post("SERVICEWRK", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.GetAllSupportTypeList = response.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
