<template>
  <v-app>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
    <!-- <LookUpCustomerDialog
      :dialogLookUpCustomer="dialogLookUpCustomer"
      :get_Current_Organization_DetailsObject="get_Current_Organization_DetailsObject"
      @clicked="dialogLookUpCustomerEmit"
    />
    <LookupDealer
      :dialogLookUpDealer="dialogLookUpDealer"
      :get_Current_Organization_DetailsObject="get_Current_Organization_DetailsObject"
      @clicked="dialogLookUpDealerEmit"
    /> -->
    <v-app-bar dense app clipped-left color="white" elevation="2">
      <v-img src="@/assets/logo.jpg" max-width="150px"></v-img>
      <v-divider vertical class="mx-3"></v-divider>
      <h3>
        {{ this.$route.params.OrgName.split("$")[1] }}
        <v-icon>mdi-menu-right-outline</v-icon> Service Request Form
      </h3>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card class="elevation-0 mt-4" height="500px">
              <v-toolbar
                dense
                class="elevation-0 mt-n2 mb-4"
                outlined
                style="border: 0.2px solid grey"
              >
                <gmap-autocomplete
                  placeholder="Search Service Location"
                  style="
                    width: 500px;
                    border-bottom: 1px solid grey;
                    font-size: 15px;
                  "
                  :value="search"
                  class="ml-0"
                  ref="clearSearch"
                  @place_changed="setPlace"
                >
                </gmap-autocomplete>
                <v-icon class="ml-n2">mdi-magnify</v-icon>
              </v-toolbar>
              <gmap-map
                :center="center"
                :zoom="20"
                style="width: 100%; height: 100%"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center = m.position"
                />
              </gmap-map>
              <v-card-text>
                <div v-if="selected_files.length != 0" class="font-weight-bold">
                  Selected Files
                </div>
                <v-data-table
                  v-if="selected_files.length != 0"
                  dense
                  class="elevation-0"
                  :headers="selected_files_header"
                  :items="selected_files"
                >
                  <template v-slot:[`item.sl_no`]="{ item }">
                    <div class="FontSize">
                      {{ selected_files.indexOf(item) + 1 }}
                    </div>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <div class="FontSize">
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.type`]="{ item }">
                    <div class="FontSize">
                      {{ item.type }}
                    </div>
                  </template>
                  <template v-slot:[`item.Action`]="{ item }">
                    <v-btn
                      icon
                      color="red"
                      @click="
                        selected_files.splice(selected_files.indexOf(item), 1)
                      "
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" class="pl-4">
            <v-form ref="form">
              <div class="font-weight-bold mb-2">Customer Information</div>
              <v-row class="mb-n8">
                <v-col cols="12" md="4" xs="12" sm="12">
                  <v-select
                    label="Customer Type *"
                    v-model="create_ticket.customer_type"
                    :items="customer_type_items"
                    outlined
                    dense
                    class="FontSize field_label_size field_height"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" xs="12" sm="12">
                  <v-text-field
                    dense
                    outlined
                    class="FontSize field_label_size field_height"
                    label="Customer ID"
                    v-model="create_ticket.customer_unique_id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" xs="12" sm="12">
                  <v-autocomplete
                    dense
                    outlined
                    class="FontSize field_label_size field_height"
                    label="Customer Category"
                    v-model="create_ticket.customer_category"
                    :items="GetAllCustomerCategoriesList"
                    item-text="setting_name"
                    item-value="setting_id"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  xs="12"
                  sm="12"
                  v-if="create_ticket.customer_type == 'BUSINESS'"
                >
                  <v-text-field
                    dense
                    outlined
                    class="FontSize field_label_size field_height"
                    label="Customer"
                    v-model="create_ticket.customer_company_name"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  :md="this.create_ticket.customer_type == 'BUSINESS' ? 6 : 12"
                  xs="12"
                  sm="12"
                >
                  <v-text-field
                    :rules="[(v) => !!v || 'Required']"
                    dense
                    outlined
                    class="FontSize field_label_size field_height"
                    label="Contact Person *"
                    v-model="create_ticket.customer_name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="4" xs="12">
                  <v-autocomplete
                    label="Country Code *"
                    v-model="create_ticket.customer_country_code"
                    :items="CountryList"
                    item-value="dial_code"
                    item-text="name"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Required']"
                    class="FontSize field_label_size field_height"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      {{ item.dial_code }}
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      {{ item.name }}({{ item.dial_code }})
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="8" xs="12">
                  <v-text-field
                    dense
                    outlined
                    class="FontSize ml-2 field_label_size field_height"
                    label="Customer Contact Number *"
                    v-model="create_ticket.customer_phone_number"
                    :rules="[
                      (v) =>
                        /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(
                          v
                        ) || 'Invalid',
                      (v) => (v && v.length < 11) || 'Invalid',
                      (v) => (v && v.length > 9) || 'Invalid',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="4" xs="12">
                  <v-autocomplete
                    dense
                    outlined
                    item-text="name"
                    :items="CountryList"
                    item-value="dial_code"
                    label="Secondary Country Code"
                    class="FontSize field_label_size field_height"
                    v-model="create_ticket.customer_secondary_country_code"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      {{ item.dial_code }}
                    </template>
                    <template slot="item" slot-scope="{ item }">
                      {{ item.name }}({{ item.dial_code }})
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="8" xs="12">
                  <v-text-field
                    dense
                    outlined
                    class="FontSize field_label_size field_height ml-2"
                    label="Secondary Contact Number"
                    v-model="create_ticket.secondary_customer_contact"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-textarea
                dense
                outlined
                class="FontSize field_label_size field_height"
                label="Service Address *"
                auto-grow
                rows="1"
                v-model="create_ticket.ticket_address"
                :rules="[(v) => !!v || 'Required']"
              ></v-textarea>
              <v-row no-gutters>
                <v-col cols="12" xs="12" md="3" sm="12">
                  <v-text-field
                    outlined
                    dense
                    label="City"
                    v-model="create_ticket.ticket_city"
                    class="FontSize field_label_size field_height mr-2"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" xs="12" md="3" sm="12">
                  <v-text-field
                    outlined
                    dense
                    label="State"
                    v-model="create_ticket.ticket_state"
                    class="FontSize field_label_size field_height mr-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="2">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Pincode"
                    v-model="create_ticket.ticket_pincode"
                    class="FontSize field_label_size field_height mr-2"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2" xs="12">
                  <v-text-field
                    dense
                    outlined
                    readonly
                    :rules="[(v) => !!v || 'Required']"
                    class="FontSize field_label_size field_height"
                    label="Latitude *"
                    v-model="create_ticket.ticket_latitude"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2" xs="12">
                  <v-text-field
                    dense
                    readonly
                    outlined
                    :rules="[(v) => !!v || 'Required']"
                    class="FontSize field_label_size field_height ml-2"
                    label="Longitude *"
                    v-model="create_ticket.ticket_longitude"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                :rules="emailRules"
                outlined
                dense
                label="Email ID "
                class="FontSize field_label_size field_height"
                v-model="create_ticket.customer_email_id"
              ></v-text-field>

              <div class="font-weight-bold mb-2">Service Information</div>
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-autocomplete
                    dense
                    outlined
                    class="FontSize field_label_size field_height"
                    label="Category *"
                    :items="categoryItems"
                    :rules="[(v) => !!v || 'Required']"
                    item-text="category_name"
                    item-value="category_id"
                    v-model="create_ticket.category"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-autocomplete
                    dense
                    outlined
                    class="ml-2 FontSize field_label_size field_height"
                    label="Product *"
                    :rules="[(v) => !!v || 'Required']"
                    :items="GetServiceByCategoriesList"
                    item-text="service_name"
                    item-value="service_id"
                    v-model="create_ticket.service_id"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field
                    dense
                    outlined
                    class="FontSize field_label_size field_height ml-2"
                    label="Serial Number"
                    v-model="create_ticket.service_serial_number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-autocomplete
                    outlined
                    dense
                    label="Ticket Type"
                    class="FontSize field_label_size field_height"
                    v-model="create_ticket.ticket_creation_type"
                    :items="ticket_creation_type_items"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-autocomplete
                    :items="GetAllServiceTypeByServiceIDList"
                    outlined
                    dense
                    label="Service Type"
                    class="FontSize field_label_size field_height ml-2"
                    item-text="service_type_name"
                    item-value="service_type_id"
                    v-model="create_ticket.service_type_id"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" xs="12" sm="12">
                  <v-combobox
                    outlined
                    dense
                    label="Problem Description *"
                    class="FontSize field_label_size field_height ml-2"
                    v-model="create_ticket.service_ticket_description"
                    :rules="[(v) => !!v || 'Required']"
                    :items="GetAllProblemDescrtionsList"
                    item-text="setting_name"
                    item-value="setting_name"
                    :return-object="false"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-autocomplete
                    outlined
                    dense
                    label="Support"
                    class="FontSize field_label_size field_height"
                    v-model="create_ticket.ticket_warranty_type"
                    :items="ticket_warranty_type_items"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-autocomplete
                    outlined
                    dense
                    label="Support Type"
                    class="FontSize field_label_size field_height ml-2"
                    v-model="create_ticket.support_type"
                    :items="GetAllSupportTypeList"
                    item-text="setting_name"
                    item-value="setting_name"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field
                    dense
                    outlined
                    class="FontSize field_label_size field_height ml-2"
                    label="Invoice Number"
                    v-model="create_ticket.ticket_invoice_number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-dialog
                    ref="menu3"
                    v-model="modalticket_invoice_date"
                    color="primary"
                    :return-value.sync="create_ticket.ticket_invoice_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="create_ticket.ticket_invoice_date"
                        class="FontSize field_label_size field_height"
                        dense
                        outlined
                        persistent-hint
                        label="Invoice Date"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="create_ticket.ticket_invoice_date"
                      color="primary"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modalticket_invoice_date = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.menu3.save(create_ticket.ticket_invoice_date)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-dialog
                    ref="menu4"
                    v-model="modal_installation_date"
                    color="primary"
                    :return-value.sync="create_ticket.installation_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="create_ticket.installation_date"
                        class="FontSize field_label_size field_height ml-2"
                        dense
                        outlined
                        persistent-hint
                        label="Installation Date"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="create_ticket.installation_date"
                      color="primary"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal_installation_date = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.menu4.save(create_ticket.installation_date)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="3" xs="12">
                  <v-checkbox
                    label="Free of Cost"
                    class="FontSize mt-0 ml-3"
                    v-model="create_ticket.is_free_of_cost"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12" xs="12">
                  <v-text-field
                    outlined
                    dense
                    class="FontSize field_label_size field_height"
                    label="Additional Comments"
                    v-model="create_ticket.additional_comments"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" xs="12">
                  <v-dialog
                    ref="menu"
                    v-model="modalprefered_visit_date"
                    color="primary"
                    :return-value.sync="create_ticket.prefered_visit_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="create_ticket.prefered_visit_date"
                        class="FontSize field_label_size field_height"
                        dense
                        outlined
                        :rules="[(v) => !!v || 'Required']"
                        persistent-hint
                        label="Preferred Visit Date *"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="create_ticket.prefered_visit_date"
                      color="primary"
                      :min="new Date().toISOString().substr(0, 10)"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modalprefered_visit_date = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.menu.save(create_ticket.prefered_visit_date)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="4" xs="12">
                  <v-dialog
                    ref="dialog"
                    v-model="modalprefered_visit_time"
                    :return-value.sync="create_ticket.prefered_visit_time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs2 }">
                      <v-text-field
                        v-model="create_ticket.prefered_visit_time"
                        class="FontSize field_label_size field_height ml-2"
                        label="Preferred Visit Time *"
                        :rules="[(v) => !!v || 'Required']"
                        append-icon="mdi-clock-time-four-outline"
                        v-bind="attrs2"
                        outlined
                        dense
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      color="primary"
                      v-if="modalprefered_visit_time"
                      v-model="create_ticket.prefered_visit_time"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modalprefered_visit_time = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog.save(create_ticket.prefered_visit_time)
                        "
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" xs="12" md="4" sm="12">
                  <v-autocomplete
                    dense
                    outlined
                    class="FontSize field_label_size field_height ml-2"
                    label="Ticket Priority"
                    v-model="create_ticket.ticket_priority"
                    :items="ticket_priority_items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-file-input
                multiple
                class="FontSize mr-2"
                :prepend-icon="null"
                append-icon="mdi-paperclip"
                v-model="files"
                label="File input"
                outlined
                dense
              ></v-file-input>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          small
          color="primary"
          @click="validateMethod"
          :loading="loading"
          class="mr-2 white--text"
          >Confirm</v-btn
        >
      </v-card-actions>
      <v-card-actions />
    </v-main>
  </v-app>
</template>
<script>
import { API } from "aws-amplify";
import { GetAllSupportType } from "@/mixins/GetAllSupportType.js";
import { GetAllCustomerCategories } from "@/mixins/GetAllCustomerCategory.js";
import { GetAllProblemDescrtions } from "@/mixins/GetAllProblemDescriprion.js";
import { GetAllServicesByCategory } from "@/mixins/GetAllServicesByCategory.js";
import { GetAllServiceTypesByService } from "@/mixins/GetAllServiceTypesByService.js";
import { GetCurrentOrganizationDetails } from "@/mixins/GetCurrentOrganizationDetails.js";
import CountryList from "@/mixins/CountryList.json";
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
var aws = require("aws-sdk");
export default {
  components: {
    Snackbar,
    Overlay,
  },
  props: {},
  mixins: [
    GetAllSupportType,
    GetAllProblemDescrtions,
    GetAllServicesByCategory,
    GetAllCustomerCategories,
    GetAllServiceTypesByService,
    GetCurrentOrganizationDetails,
  ],
  data: () => ({
    count: 0,
    Toggle: 0,
    row: "CUSTOMER",
    files: null,
    search: null,
    overlay: false,
    loading: false,
    currentPlace: null,
    dialogLookUpDealer: false,
    dialogLookUpCustomer: false,
    modal_installation_date: false,
    modalprefered_visit_date: false,
    modalprefered_visit_time: false,
    modalticket_invoice_date: false,
    places: [],
    emailRules: [],
    CountryList: [],
    categoryItems: [],
    selected_files: [],
    ticket_s3_files: [],
    CountryCodeItems: [],
    GetAllSupportTypeList: [],
    GetServiceByCategoriesList: [],
    GetAllProblemDescrtionsList: [],
    GetAllCustomerCategoriesList: [],
    selected_files_header: [
      { text: "Sl No.", value: "sl_no" },
      { text: "Name", value: "name" },
      { text: "Type", value: "type" },
      { text: "Action", value: "Action" },
    ],
    ticket_priority_items: ["High", "Medium", "Low"],
    markers: [
      {
        position: {},
      },
    ],
    customer_type_items: ["INDIVIDUAL", "BUSINESS"],
    ticket_creation_type_items: [
      { text: "Breakdown", value: "BREAKDOWN" },
      { text: "Installation", value: "INSTALLATION" },
      { text: "Schedule Service", value: "SCHEDULE_SERVICE" },
      { text: "Core Service Job", value: "CORE_SERVICE_JOB" },
      { text: "Non-Core Service Job", value: "NON_CORE_SERVICE_JOB" },
      { text: "Sundary Job", value: "SUNDARY_JOB" },
      { text: "Other", value: "OTHER" },
    ],
    ticket_warranty_type_items: [
      { text: "GUARANTEE", value: "GUARANTEE" },
      { text: "WARRANTY", value: "WARRANTY" },
      { text: "CONTRACT", value: "AMC" },
      { text: "EXTENDED WARRANTY", value: "EXTENDED_WARRANTY" },
      { text: "NONE", value: "NO_WARRANTY" },
    ],
    SnackBarComponent: {},
    create_ticket: {
      customer_name: "",
      customer_company_name: "",
      customer_unique_id: "",
      customer_category: [],
      customer_type: "BUSINESS",
      customer_country_code: "+91",
      customer_phone_number: "",
      secondary_customer_contact: "",
      ticket_latitude: "",
      ticket_longitude: "",
      ticket_address: "",
      ticket_city: "",
      ticket_state: "",
      ticket_pincode: "",
      customer_email_id: "",
      service_id: "",
      ticket_creation_type: "",
      service_serial_number: "",
      service_type_id: "",
      component_ids: [],
      prefered_visit_date: "",
      ticket_warranty_type: "",
      support_type: "",
      prefered_visit_time: "",
      additional_comments: "",
      is_free_of_cost: false,
      ticket_priority: "",
      installation_date: "",
      category: "",
      dealers_name: "",
      dealers_email_id: "",
      dealer_address: "",
      dealer_latitude: "",
      dealer_longitude: "",
      dealers_phone_number: "",
      ticket_invoice_number: "",
      dealers_country_code: "+91",
      service_ticket_description: "",
      customer_secondary_country_code: "+91",
    },
    center: { lat: 0, lng: 0 },
    get_Current_Organization_DetailsObject: {},
  }),
  watch: {
    "create_ticket.ticket_warranty_type"(val) {
      this.GetAllSupportTypeMethod(val);
    },
    files(val) {
      if (val.length != 0 && val != undefined && val != null)
        for (let i = 0; i < val.length; i++) {
          this.selected_files.push(val[i]);
        }
    },
    "create_ticket.category"(val) {
      this.GetAllServicesByCategoryMethod(val.category_id);
    },
    "create_ticket.customer_email_id"(val) {
      if (val != "" && val != null) {
        this.emailRules = [
          (v) => !!v || "Email ID Is Required",
          (v) => /.+@.+/.test(v) || "Email Must Be Valid",
        ];
      } else {
        this.emailRules = [];
      }
    },
    "create_ticket.service_id"(val) {
      this.GetAllServiceTypeBySericeIDMethod(val.service_id);
    },
  },
  mounted() {
    this.GetGetCurrentOrganizationDetailsMethod();
    this.GetCurrentLocation();
    this.CountryList = CountryList;
    setTimeout(() => {
      this.GetAllCustomerCategoriesMethod();
      this.GetAllProblemDescrtionsMethod();
    }, 1000);
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        if (this.selected_files.length != 0) {
          this.count = 0;
          this.uploadMediaToS3();
        } else {
          this.CreateTicket();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all the required details",
        };
      }
    },
    async uploadMediaToS3() {
      var self = this;
      self.loading = true;
      const fr = new FileReader();
      fr.readAsDataURL(self.selected_files[self.count]);
      fr.addEventListener("load", async () => {
        var imageUrl = [];
        imageUrl = fr.result.replace(
          "data:" + self.selected_files[self.count].type + ";base64,",
          ""
        );
        var keyValue =
          "ticket" +
          "/" +
          self.create_ticket.customer_phone_number +
          "/" +
          Date.now() +
          "/" +
          self.selected_files[self.count].name;
        var params = {
          Bucket: "servicewrkcabinet",
          Key: keyValue,
          Body: Buffer.from(imageUrl, "base64"),
          ContentType: self.selected_files[self.count].type,
          ACL: "public-read-write",
        };
        var s3Client = new aws.S3({
          region: "ap-south-1",
          accessKeyId: "AKIARAJXABES432LKNPU",
          secretAccessKey: "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",
        });
        // var s3Client = new aws.S3({
        //   region: "ap-south-1",
        //   accessKeyId: "AKIARAJXABES6RGOPDVD",
        //   secretAccessKey: "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",
        // });
        s3Client.putObject(params, function (err) {
          if (err) {
            console.log(err);
            self.loading = false;
          } else {
            if (self.count != self.selected_files.length - 1) {
              self.ticket_s3_files.push({
                file_name: self.selected_files[self.count].name,
                file_type: self.selected_files[self.count].type,
                file_url:
                  "https://servicewrkcabinet.s3.ap-south-1.amazonaws.com/" +
                  keyValue,
              });
              self.count += 1;
              self.uploadMediaToS3();
            } else {
              self.ticket_s3_files.push({
                file_name: self.selected_files[self.count].name,
                file_type: self.selected_files[self.count].type,
                file_url:
                  "https://servicewrkcabinet.s3.ap-south-1.amazonaws.com/" +
                  keyValue,
              });
              self.count = 0;
              self.CreateTicket(self.ticket_s3_files);
            }
          }
        });
      });
    },
    GetCurrentLocation() {
      var self = this;
      navigator.geolocation.getCurrentPosition(function (pos) {
        self.center.lat = pos.coords.latitude;
        self.center.lng = pos.coords.longitude;
        self.create_ticket.ticket_latitude = pos.coords.latitude;
        self.create_ticket.ticket_longitude = pos.coords.longitude;
        self.markers = [];
        self.markers.push({
          position: {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          },
        });
      });
    },
    setPlace(place) {
      this.currentPlace = place;
      (this.center.lat = this.currentPlace.geometry.location.lat()),
        (this.center.lng = this.currentPlace.geometry.location.lng());
      this.create_ticket.ticket_latitude =
        this.currentPlace.geometry.location.lat();
      this.create_ticket.ticket_longitude =
        this.currentPlace.geometry.location.lng();
      this.create_ticket.dealer_latitude =
        this.currentPlace.geometry.location.lat();
      this.create_ticket.dealer_longitude =
        this.currentPlace.geometry.location.lng();
      this.create_ticket.ticket_address = place.formatted_address;
      this.create_ticket.dealer_address = place.formatted_address;
      this.TicketName = place.name;
      self.markers = [];
      self.markers.push({
        position: {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        },
      });
    },
    async CreateTicket(ticket_s3_files) {
      var input_body = {};
      input_body = {
        command: "raiseRFT",
        organization_id:
          this.get_Current_Organization_DetailsObject.organization_id,
        customer_name: this.create_ticket.customer_name,
        customer_company_name:
          this.create_ticket.customer_company_name != undefined &&
          this.create_ticket.customer_company_name != null &&
          this.create_ticket.customer_company_name != ""
            ? this.create_ticket.customer_company_name
            : undefined,
        customer_unique_id:
          this.create_ticket.customer_unique_id != undefined &&
          this.create_ticket.customer_unique_id != null &&
          this.create_ticket.customer_unique_id != ""
            ? this.create_ticket.customer_unique_id
            : undefined,
        customer_category_id:
          this.create_ticket.customer_category != undefined &&
          this.create_ticket.customer_category != null &&
          this.create_ticket.customer_category != []
            ? this.create_ticket.customer_category.setting_id
            : undefined,
        customer_category_name:
          this.create_ticket.customer_category != undefined &&
          this.create_ticket.customer_category != null &&
          this.create_ticket.customer_category != []
            ? this.create_ticket.customer_category.setting_name
            : undefined,
        customer_type:
          this.create_ticket.customer_type != undefined &&
          this.create_ticket.customer_type != null &&
          this.create_ticket.customer_type != ""
            ? this.create_ticket.customer_type
            : undefined,
        category_id: this.create_ticket.category.category_id,
        category_name: this.create_ticket.category.category_name,
        service_id: this.create_ticket.service_id.service_id,
        service_name: this.create_ticket.service_id.service_name,
        ticket_address: this.create_ticket.ticket_address,
        ticket_city:
          this.create_ticket.ticket_city != undefined &&
          this.create_ticket.ticket_city != null &&
          this.create_ticket.ticket_city != ""
            ? this.create_ticket.ticket_city
            : undefined,
        ticket_state:
          this.create_ticket.ticket_state != undefined &&
          this.create_ticket.ticket_state != null &&
          this.create_ticket.ticket_state != ""
            ? this.create_ticket.ticket_state
            : undefined,
        ticket_pincode:
          this.create_ticket.ticket_pincode != undefined &&
          this.create_ticket.ticket_pincode != null &&
          this.create_ticket.ticket_pincode != ""
            ? this.create_ticket.ticket_pincode
            : undefined,
        ticket_latitude: this.create_ticket.ticket_latitude,
        ticket_longitude: this.create_ticket.ticket_longitude,
        prefered_visit_date: this.create_ticket.prefered_visit_date,
        customer_country_code: this.create_ticket.customer_country_code,
        customer_phone_number: this.create_ticket.customer_phone_number,
        secondary_customer_contact:
          this.create_ticket.secondary_customer_contact != undefined &&
          this.create_ticket.secondary_customer_contact != null &&
          this.create_ticket.secondary_customer_contact != ""
            ? this.create_ticket.secondary_customer_contact
            : undefined,
        customer_secondary_country_code:
          this.create_ticket.customer_secondary_country_code != undefined &&
          this.create_ticket.customer_secondary_country_code != null &&
          this.create_ticket.customer_secondary_country_code != ""
            ? this.create_ticket.customer_secondary_country_code
            : undefined,
        service_ticket_description:
          this.create_ticket.service_ticket_description,
      };

      this.loading = true;
      const path = "createrftwebform";
      const myInit = {
        body: input_body,
      };
      if (
        this.create_ticket.customer_email_id != undefined &&
        this.create_ticket.customer_email_id != null &&
        this.create_ticket.customer_email_id != ""
      ) {
        input_body.customer_email_id = this.create_ticket.customer_email_id;
      }
      if (
        this.create_ticket.service_type_id != undefined &&
        this.create_ticket.service_type_id != null &&
        this.create_ticket.service_type_id != ""
      ) {
        input_body.service_type_id = this.create_ticket.service_type_id.service_type_id;
      }
      if (
        this.create_ticket.service_type_id != undefined &&
        this.create_ticket.service_type_id != null &&
        this.create_ticket.service_type_id != ""
      ) {
        input_body.service_type_name = this.create_ticket.service_type_id.service_type_name;
      }
      if (
        this.create_ticket.service_serial_number != undefined &&
        this.create_ticket.service_serial_number != null &&
        this.create_ticket.service_serial_number != ""
      ) {
        input_body.service_serial_number =
          this.create_ticket.service_serial_number;
      }
      if (
        this.create_ticket.ticket_creation_type != undefined &&
        this.create_ticket.ticket_creation_type != null &&
        this.create_ticket.ticket_creation_type != ""
      ) {
        input_body.ticket_creation_type =
          this.create_ticket.ticket_creation_type;
      }
      if (
        this.create_ticket.ticket_warranty_type != undefined &&
        this.create_ticket.ticket_warranty_type != null &&
        this.create_ticket.ticket_warranty_type != ""
      ) {
        input_body.ticket_warranty_type =
          this.create_ticket.ticket_warranty_type;
      }
      if (
        this.create_ticket.support_type != undefined &&
        this.create_ticket.support_type != null &&
        this.create_ticket.support_type != ""
      ) {
        input_body.support_type = this.create_ticket.support_type;
      }
      if (
        this.create_ticket.prefered_visit_time != undefined &&
        this.create_ticket.prefered_visit_time != null &&
        this.create_ticket.prefered_visit_time != ""
      ) {
        input_body.prefered_visit_time = this.create_ticket.prefered_visit_time;
      }
      if (
        this.create_ticket.ticket_warranty_type != undefined &&
        this.create_ticket.ticket_warranty_type != null &&
        this.create_ticket.ticket_warranty_type != ""
      ) {
        input_body.ticket_warranty_details = {
          ticket_invoice_number: this.create_ticket.ticket_invoice_number,
          ticket_invoice_date: this.create_ticket.ticket_invoice_date,
        };
      }
      if (
        this.create_ticket.additional_comments != undefined &&
        this.create_ticket.additional_comments != null &&
        this.create_ticket.additional_comments != ""
      ) {
        input_body.additional_comments = this.create_ticket.additional_comments;
      }
      if (
        this.create_ticket.is_free_of_cost != undefined &&
        this.create_ticket.is_free_of_cost != null &&
        this.create_ticket.is_free_of_cost != ""
      ) {
        input_body.is_free_of_cost = this.create_ticket.is_free_of_cost;
      }
      if (
        this.create_ticket.ticket_priority != undefined &&
        this.create_ticket.ticket_priority != null &&
        this.create_ticket.ticket_priority != ""
      ) {
        input_body.ticket_priority = this.create_ticket.ticket_priority;
      }
      if (
        this.create_ticket.installation_date != undefined &&
        this.create_ticket.installation_date != null &&
        this.create_ticket.installation_date != ""
      ) {
        input_body.installation_date = this.create_ticket.installation_date;
      }
      if (ticket_s3_files != undefined) {
        input_body.attachments = ticket_s3_files;
      }
      API.post("SERVICEWRK", path, myInit)
        .then((response) => {
          if (response.status == "SUCCESS") {
            this.$router.push("/Result/SucessRoute");
          }
          this.loading = false;
          console.log("response", response);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
