import { API } from "aws-amplify";
export const GetAllServicesByCategory = {
  data() {
    return {
      GetServiceByCategoriesList: [],
      overlay: false,
      noDataText: "",
    };
  },
  methods: {
    async GetAllServicesByCategoryMethod(category_id) {
      const path = "listservicesbycategory";
      const myInit = {
        body: {
          command: "listServicesByCategory",
          category_id: category_id,
          organization_id: this.get_Current_Organization_DetailsObject.organization_id,
        },
      };
      API.post("SERVICEWRK", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.GetServiceByCategoriesList = response.data.items
          }
          // else{
          //   this.$router.push("/Result/ErrorRoute");
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
