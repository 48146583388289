import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    // key: "AIzaSyA7XUvuw2S89AQK0frkmHbplYvyHkXS4JM",
    key:"AIzaSyBUp8E7OghfEFg9fJ1ePokoOhE1I8MaD-M",
    libraries: "places",
  },
});
Vue.config.productionTip = false;
import Amplify from "aws-amplify";
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "SERVICEWRK",
        endpoint: "https://ojnwfkkbma.execute-api.ap-south-1.amazonaws.com/dev/",
      },
    ],
  },
});
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");