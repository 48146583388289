<template>
  <v-app>
    <v-card class="elevation-0" align="center">
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" align="center">
            <v-card height="300px" outlined>
              <v-toolbar dark dense color="primary" class="elevation-0"><h1>Success</h1> </v-toolbar>
              <v-card-text>
                <v-icon color="primary" size="50px" class="mt-6">mdi-text-box-check-outline</v-icon>
                <h2 class="mt-4">Ticket Created Successfully!!.Please close the window.</h2>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-app>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    closeWindow() {
      window.close();
    },
  },
};
</script>
