import Vue from "vue";
import VueRouter from "vue-router";
import WebForm from "../views/WebForm.vue";
import SucessRoute from "../views/SucessRoute.vue";
import ErrorRoute from "../views/ErrorRoute.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/:OrgName",
    name: "WebForm",
    component: WebForm,
  },
  {
    path: "/Result/SucessRoute",
    name: "SucessRoute",
    component: SucessRoute,
  },
  {
    path: "/Result/ErrorRoute",
    name: "ErrorRoute",
    component: ErrorRoute,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
