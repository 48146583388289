import { API } from "aws-amplify";
export const GetAllServiceTypesByService = {
  data() {
    return {
      GetAllServiceTypeByServiceIDList: [],
      overlay: false,
      noDataText: "",
    };
  },
  methods: {
    async GetAllServiceTypeBySericeIDMethod(service_id) {
      this.overlay = true;
      const path = "listservicebasedservicetypes";
      const myInit = {
        body: {
          command: "listServiceBasedServiceTypes",
          service_id: service_id,
        },
      };
      API.post("SERVICEWRK", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.GetAllServiceTypeByServiceIDList = response.data.items;
          }
          // else{
          //   this.$router.push("/Result/ErrorRoute");
          // }
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          // this.$router.push("/Result/ErrorRoute");
          console.log(error);
        });
    },
  },
};
