import { API } from "aws-amplify";
export const GetCurrentOrganizationDetails = {
  data() {
    return {
      get_Current_Organization_DetailsObject: {},
      overlay: false,
      RouterList: [],
    };
  },
  mounted(){
    console.log("this.$route.params",this.$route.params)
  },
  methods: {
    async GetGetCurrentOrganizationDetailsMethod() {
      this.overlay = true;
      const path = "getenterprisedetails";
      const myInit = {
        body: {
          command: "getEnterpriseDetails",
          // organization_name: this.$route.params.OrgName.split("$")[1],
          organization_name: this.$route.params.OrgName.split("$")[1].includes('_') ? this.$route.params.OrgName.split("$")[1].replaceAll('_', ' ') : this.$route.params.OrgName.split("$")[1],
        },
      };
      API.post("SERVICEWRK", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.get_Current_Organization_DetailsObject = response.data.items;
            console.log("yyyyyyy",this.get_Current_Organization_DetailsObject);
            this.categoryItems = this.get_Current_Organization_DetailsObject.categories_list;
          }
          else{
            this.$router.push("/Result/ErrorRoute");
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log("Error!!!",error)
          // this.$router.push("/Result/ErrorRoute");
          this.overlay = false;
          console.log(error);
        });
    },
  },
};
